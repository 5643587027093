import {Column, DefaultCellTypes, Row} from "@silevis/reactgrid";
import {CompositeReport, DateReport} from "../../../../api/composite/report/CompositeReportApi";
import dayjs from "dayjs";
import {Device} from "../../../../api/device/DeviceApi";

const hoursNumbers = Array.from({ length: 24 }, (_, i) => i);
const hours = hoursNumbers.map(i => i < 10 ? `0${i}:00` : `${i}:00`);

const missingTempStyle =  { backgroundColor: 'rgb(255, 255, 0, .15)' };

const suggestionStyle = { backgroundColor: 'rgba(64,255,0,0.3)' }

class ExcelViewHelper {
    static getHour(index: number) {
        return index < 10 ? `0${index}:00` : `${index}:00`;
    }

    static getRecord(dateReport: DateReport, hour: string): any {
        const records = dateReport.records
            .filter(r => '_original_time' in r)
            .filter(r => (r as any)['_original_time'] === hour);

        if (records.length > 0) return records[0];

        return records.length > 0 ? records[0] : { '_original_time': hour } as any ;
    }

    static getCellStyle(temp1: number | undefined, temp2: number | undefined, device: Device | undefined, record: any): any | undefined {
        if (record["Suggest"] === true) {
            return suggestionStyle;
        }

        if (temp1 === undefined || temp2 === undefined || temp1 === null || temp2 === null) {
            return missingTempStyle;
        }

        if (!device) return undefined;

        const min = device.minAllowableTemp;
        const max = device.maxAllowableTemp;

        if (min !== undefined && min !== null && (temp1 < min || temp2 < min)) {
            return { color: 'red', backgroundColor: 'rgb(255, 0, 0, .15)' };
        }

        if (max !== undefined && max !== null && (temp1 > max || temp2 > max)) {
            return { color: 'red', backgroundColor: 'rgb(255, 0, 0, .15)' }
        }

        return undefined; // No colors.
    }

    static getCells(dateReport: DateReport, device: Device | undefined) {
        const arr: DefaultCellTypes[] = [];

        for (let index = 0; index < 24; index++) {
            const hour = this.getHour(index);
            const record = this.getRecord(dateReport, hour);
            const t1: number|undefined = 'Temp1' in record ? record['Temp1'] : undefined;
            const t2: number|undefined = 'Temp2' in record ? record['Temp2'] : undefined;

            arr.push(
                {
                    text: `${t1 ?? '-'} , ${t2 ?? '-'}`,
                    type: `text`,
                    style: this.getCellStyle(t1, t2, device, record)
                }
            );
        }

        return arr;
    }

    static getRow(dateReport: DateReport, device: Device | undefined): Row {
        // const date = dayjs(dateReport.date).toDate();
        const cells: DefaultCellTypes[] =  [
            {
                type: 'text',
                text: `${dateReport.date[2]}.${dateReport.date[1]}.${dateReport.date[0]}`,
                nonEditable: true
            },
            ...this.getCells(dateReport, device)
        ]

        return {
            rowId: dateReport.id,
            cells: cells
        }
    }

    static getRows(cr: CompositeReport, device: Device | undefined): Row[] {
        const dateReport = cr.sections?.flatMap(section => section.content) ?? [];
        return dateReport.map(dr => this.getRow(dr, device));
    }

    static getHeaderRows(): Row[] {
        const cells = hours.map(hour => ({type: 'header', text: hour}) as DefaultCellTypes);

        return [
            {
                rowId: '#',
                cells: [{type: 'header', text: 'Godzina'}, ...cells]
            }
        ];
    }

    static getColumns(): Column[] {
        const hoursNumbers = Array.from({ length: 24 }, (_, i) => i);
        const hours = hoursNumbers.map(i => i < 10 ? `0${i}:00` : `${i}:00`);

        return [
            { columnId: '#', width: 100 },
            ...(hours.map(i => ({columnId: i, width: 82}) as Column))
        ];
    }
}

export default ExcelViewHelper;