import dayjs from "dayjs";
import arraySupport from "dayjs/plugin/arraySupport";

export type DateTimeViewAttrs = {
    dateTime?: any;
}

function DateTimeView({dateTime}: DateTimeViewAttrs) {
    // const data = [2024, 12, 3, 12, 28, 16, 614000000];
    const data = dateTime;

    dayjs.extend(arraySupport);

    if (!dateTime) {
        return (
            <>
                -
            </>
        )
    }

    const date = dayjs()
        .day(data[2] - 1)
        .year(data[0])
        .month(data[1] - 1)
        .hour(data[3] - 1)
        .minute(data[4])
        .add(2, 'hour');

    return (
        <>
            {/*{`${date.year()}-${formatValue(date.month() + 1)}-${formatValue(date.date())}, ${formatValue(date.hour())}:${formatValue(date.minute())}`}*/}
            { date.format('YYYY-MM-DD HH:mm') }
        </>
    );
}

export default DateTimeView;
