import PickerDialog, {Item} from "./PickerDialog";
import Page from "../../models/api/Page";
import {Device, DeviceApi} from "../../api/device/DeviceApi";
import {IconChevronRight} from "@tabler/icons-react";

type Attrs = {
    device?: Device | null;
    setDevice: (d: Device | null) => void;
    isOpen: boolean;
    title?: string;
    justClose: () => void;
    active?: string;
};

function DevicePickerDialog(attrs: Attrs) {
    const pickAndClose = (item: Item) => {
        DeviceApi.getById(item.value)
            .then(attrs.setDevice)
            .then(attrs.justClose);
    }

    const mapData = (page: Page<any>) => {
        if (!page?.content) {
            return [];
        }

        const mappedItems = page.content.map(device => {
            return {
                value: device.id,
                title: device.name ?? '<Brak nazwy urządzenia>',
                description: device.unit,
                key: `device-${device.id}-in-DevicePickerDialog`,
                right: <IconChevronRight size="0.8rem" stroke={1.5} className="mantine-rotate-rtl"/>
            } as Item
        });

        return [...mappedItems];
    }

    const loadData = (query: string, page: number, limit: number) => {
        return DeviceApi.search({q: query, page, limit});
    }

    return (
        <PickerDialog
            pickAndClose={pickAndClose}
            title={attrs.title}
            isOpen={attrs.isOpen}
            justClose={attrs.justClose}
            loadData={loadData}
            button={{ text: 'Użyj' }}
            mapData={mapData}
            page={{ limit: 5 }}
        />
    )
}

export default DevicePickerDialog;