import {Button, Checkbox, Modal, Stack, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import CalendarEventApi from "../../../../api/calendar/event/CalendarEventApi";
import {useState} from "react";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";
import {DatePickerInput} from "@mantine/dates";
import DevicePickerDialog from "../../../picker-dialog/DevicePickerDialog";
import {Device} from "../../../../api/device/DeviceApi";

export type CreateCalendarEventDialogAttrs = {
    isOpen: boolean;
    justClose: () => void;
    closeAndUpdate: () => void;
}

export type CreateEventModel = {
    date: Date | null;
    comment: string;
    endDate: Date | null;
}

// noinspection DuplicatedCode
function formatDate(date: Date|null) {
    if (!date) { return undefined }
    const year = date.getFullYear();
    let month: string|number = date.getMonth() + 1;
    if (month < 10) {
        month = '0' + month;
    }
    let day: string|number = date.getDate();
    if (day < 10) {
        day = '0' + day;
    }
    return `${year}-${month}-${day}`;
}

function CreateCalendarEventDialog(attrs: CreateCalendarEventDialogAttrs) {
    const [selectedDevice, setSelectedDevice] = useState<Device | null>();
    const [isPickOpen, setPickOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [addPrefix, setAddPrefix] = useState(true);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {date: new Date(), comment: '', endDate: null },
        validate: {
            comment: c => c.length < 2 ? 'Przynajmniej dwa znaki' : null
        }
    });

    const doSubmitSuccess = () => {
        showNotification({
            title: 'Zapisano wydarzenie',
            message: 'Wydarzenie zostało utworzone',
            color: 'green',
            icon: <IconCheck/>
        });
    }

    const doSubmitError = () => {
        showNotification({
            title: 'Nie udało się zapisać wydarzenia',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doSubmit = (values: CreateEventModel) => {
        startLoading();

        CalendarEventApi.create({...values, unit: selectedDevice?.unit!!, date: formatDate(values.date)!!, endDate: formatDate(values.endDate), addPrefixAutomatically: addPrefix})
            .then(doSubmitSuccess)
            .then(attrs.closeAndUpdate)
            .catch(doSubmitError)
            .finally(stopLoading);
    }

    return (
        <>
            <Modal opened={attrs.isOpen} onClose={attrs.justClose} title="Nowe wydarzenie">
                <form onSubmit={form.onSubmit(doSubmit)}>
                    <Stack>
                        <TextInput
                            withAsterisk
                            placeholder="Jednostka"
                            value={selectedDevice?.unit}
                        />

                        <Button
                            children="Wyszukaj"
                            onClick={() => {setPickOpen(true)}}
                        />

                        <DatePickerInput
                            withAsterisk
                            placeholder="Data"
                            key={form.key('date')}
                            { ...form.getInputProps('date') }
                        />

                        <DatePickerInput
                            clearable={true}
                            placeholder="Data (Do)"
                            key={form.key('endDate')}
                            { ...form.getInputProps('endDate') }
                        />

                        <TextInput
                            withAsterisk
                            placeholder="Komentarz"
                            description="data/y będą dodane na raporcie końcowym"
                            key={form.key('comment')}
                            { ...form.getInputProps('comment') }
                        />

                        <Checkbox
                            label="Dodawaj dzień do komentarzy"
                            checked={addPrefix}
                            onChange={e => setAddPrefix(e.target.checked)}
                        />

                        <Button type="submit" loading={loading}>
                            Zapisz
                        </Button>
                    </Stack>
                </form>
            </Modal>

            <DevicePickerDialog
                device={selectedDevice}
                setDevice={setSelectedDevice}
                isOpen={isPickOpen}
                justClose={() => setPickOpen(false)}
            />
        </>
    );
}

export default CreateCalendarEventDialog;