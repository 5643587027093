import {useEffect, useState} from "react";
import PageHeader from "../../../components/layout/PageHeader";
import {ActionIcon, Menu} from "@mantine/core";
import PageContent from "../../../components/layout/PageContent";
import {DataTable, DataTableColumn, DataTableSortStatus, useDataTableColumns} from "mantine-datatable";
import Page from "../../../models/api/Page";
import {Action, SearchQuery, SendReportApi, SendReportItem, SendStatus} from "../../../api/send/report/SendReportApi";
import {showNotification} from "@mantine/notifications";
import Cell, {CellHighlight} from "../../../components/table/Cell";
import {modals} from "@mantine/modals";
import DateTimeView from "../../../components/date/DateTimeView";
import PaginationFooter from "../../../components/pagination/PaginationFooter";
import EditSendReportDialog from "./EditSendReportDialog";
import {useDisclosure, useSessionStorage} from "@mantine/hooks";
import {useContextMenu} from "mantine-contextmenu";
import {IconEdit, IconSend, IconTrash} from "@tabler/icons-react";
import SendReportStatusCell from "../../../components/send/report/SendStatusCell";
import SendReportFilter from "./SendReportFilter";

type SendReportMenuAttrs = {
    deleteSelected: () => void;
    sendSelected: () => void;
    doFilter: () => void;
}

function SendReportMenu(attrs: SendReportMenuAttrs) {
    return (
        <Menu width={200}>
            <Menu.Target>
                <ActionIcon variant="transparent">
                    <i className="fa fa-filter"></i>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Filtrowanie</Menu.Label>
                <Menu.Item onClick={attrs.doFilter}>Filtrowanie wyników</Menu.Item>

                <Menu.Label>Zaznaczenie wielokrotne</Menu.Label>
                <Menu.Item onClick={attrs.sendSelected}>Wyślij do klientów</Menu.Item>
                <Menu.Item onClick={attrs.deleteSelected} color="red">Usuń zaznaczone</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    )
}

function SendReport() {
    const key = 'SendReport-table-key';

    // @ts-ignore
    const columns: DataTableColumn[] = [
        {
            accessor: 'id',
            title: '_id',
            toggleable: true,
            defaultToggle: false,
            render: (r) => <Cell orOptional={r.id}/>
        },
        {
            accessor: 'emailAddress',
            title: 'Adres E-mail',
            toggleable: true,
            render: (r) => <CellHighlight searchText={searchQuery.emailAddress} text={r.emailAddress as unknown as string}/>,
            sortable: true,
            draggable: true
        },
        {
            accessor: 'subject',
            title: 'Temat',
            toggleable: true,
            render: (r) => <CellHighlight searchText={searchQuery.q} text={r.subject} />,
            sortable: true,
            draggable: true
        },
        {
            accessor: 'title',
            title: 'Tytuł (raportu)',
            toggleable: true,
            draggable: true,
            sortable: true,
            render: r => <CellHighlight searchText={searchQuery.q} text={r.title ?? (r.compositeReport as any | undefined)?.title}/>
        },
        {
            accessor: 'compositeReport.unit',
            title: 'Jednostka (z raportu)',
            toggleable: true,
            draggable: true
        },
        {
            accessor: 'compositeReport.generatedAt',
            title: 'Czas utworzenia (raportu)',
            toggleable: true,
            render: (r) => <DateTimeView dateTime={(r as any).compositeReport?.createdAt} />,
            draggable: true
        },
        {
            accessor: 'client.visibleName',
            title: 'Klient',
            toggleable: true,
            render: (r) => <Cell orOptional={(r as any).client?.visibleName}/>,
            draggable: true
        },
        {
            accessor: 'client.emailAddress',
            title: 'Adres E-mail (Klient)',
            toggleable: true,
            render: (r) => <Cell orOptional={(r as any).client?.emailAddress}/>,
            draggable: true
        },

        {
            accessor: 'generatedAt',
            title: 'Utworzono',
            toggleable: true,
            sortable: true,
            draggable: true,
            render: (r) => <Cell orOptional={ r.generatedAt ? <DateTimeView dateTime={r.generatedAt as number[]}/> : null }/>
        },

        {
            accessor: 'processedAt',
            title: 'Przetworzono',
            toggleable: true,
            sortable: true,
            draggable: true,
            render: (r) => <Cell orOptional={ r.processedAt ? <DateTimeView dateTime={r.processedAt as number[]}/> : null }/>
        },

        {
            accessor: 'sendStatus',
            title: 'Status wysyłki',
            toggleable: true,
            render: (r) => <SendReportStatusCell status={r.sendStatus as SendStatus | undefined}/>,
            sortable: true,
            draggable: true
        }
    ];

    const [page, setPage] = useState<Page<SendReportItem>>();
    const [searchQuery, setSearchQuery] = useState<SearchQuery>({ limit: 50, page: 0 });
    const [selectedRecords, setSelectedRecords] = useState<SendReportItem[]>([]);
    const [editSendReportItem, setEditSendReportItem] = useState<SendReportItem>();
    const [isEditDialogOpen, editDialog] = useDisclosure();
    const {showContextMenu} = useContextMenu();
    const [sortStatus, setSortStatus] = useSessionStorage<DataTableSortStatus<SendReportItem>>({
        key: 'SendReportItem_SendStatus',
        defaultValue: {
            columnAccessor: 'generatedAt',
            direction: 'desc'
        }
    });
    const [isFiltersVisible, setFiltersVisible] = useState(false);

    const toggleFilters = () => {
        setFiltersVisible(!isFiltersVisible);
    }

    const setLimit = (limit: number) => {
        setSearchQuery({...searchQuery, limit});
    }

    const setPageNumber = (pn: number) => {
        setSearchQuery({...searchQuery, page: pn});
    }

    const getSelectedElements = () => selectedRecords.map(x => x.id) as string[];

    const { effectiveColumns } = useDataTableColumns({key, columns});

    const [loading, setLoading] = useState(false);

    const startLoading = () => { setLoading(true) };
    const stopLoading = () => { setLoading(false) };

    const onDeleteSelectedError = () => {
        showNotification({
            title: 'Nie udało się usunąć.',
            message: 'Jeśli problem będzie się utrzymywał, proszę skontaktować się z administratorem.',
            color: 'red'
        });
    };

    const onDeleteSelectedSuccess = () => {
        showNotification({
            title: 'Usunięto zaznaczone elementy.',
            message: 'Pomyślnie usunięto zaznaczone elementy.',
            color: 'green'
        });
    };

    const doDeleteSelected = () => {
        setLoading(true);

        SendReportApi.performAction({selectedElements: getSelectedElements(), action: 'delete'})
            .then(reload)
            .then(onDeleteSelectedSuccess)
            .then(() => setSelectedRecords([]))
            .catch(onDeleteSelectedError)
            .finally(stopLoading);
    }

    const deleteSelected = () => {
        modals.openConfirmModal({
            title: "Usuwanie zaznaczonych",
            children: 'Czy na pewno chcesz usunąć zaznaczone raporty urządzeń. Nie spowoduje to usunięcia oryginalnych plików.',
            labels: { confirm: 'OK', cancel: 'Anuluj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: doDeleteSelected
        });
    }

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSendSelectedError = () => {
        showNotification({
            title: 'Nie udało się wysłać raportów',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSendSelectedSuccess = () => {
        showNotification({
            title: 'Wysłano!',
            message: 'Raporty zostały wysłane do klientów!',
            color: 'green'
        });
    }

    const doSendSelected = () => {
        startLoading();

        SendReportApi.performAction({selectedElements: getSelectedElements(), action: 'send_item'})
            .then(doSendSelectedSuccess)
            .then(reload)
            .catch(doSendSelectedError)
            .finally(stopLoading);
    }

    const sendSelected = () => {
        modals.openConfirmModal({
            title: `Czy chcesz wysłać ${selectedRecords.length} do klientów?`,
            children: `System spróbuje wysłać wszystkie wiadomości do przypisanych klientów / adresów e-mail. Czy na pewno chcesz kontynuować?`,
            labels: { confirm: `Tak, wyślij ${selectedRecords.length} wiadomości do klientów`, cancel: 'Anuluj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: doSendSelected
        });
    }

    const editSendReport = (item: SendReportItem) => {
        setEditSendReportItem(item);
        editDialog.open();
    }

    const sendSingleItemSuccess = () => {
        showNotification({
            title: 'Przetworzono pojedynczy raport',
            message: 'Proszę sprawdzić, czy został pozytywnie wysłany'
        });
    }

    const sendSingleItemError = () => {
        showNotification({
            title: 'Nie udało się przetworzyć raportu',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSendSingleItem = (item: SendReportItem) => {
        const action: Action = { action: 'send_item', selectedElements: [item.id!!] }
        startLoading();

        SendReportApi.performAction(action)
            .then(reload)
            .then(sendSingleItemSuccess)
            .catch(sendSingleItemError)
            .finally(stopLoading);
    }

    const sendSingleItem = (item: SendReportItem) => {
        if (!item.emailAddress && !item.client?.emailAddress) {
            showNotification({
                title: 'Nie podano adresu E-mail',
                message: 'Nie można wysłać tej wiadomości',
                color: 'red'
            });

            return
        }

        modals.openConfirmModal({
            title: `Czy chcesz wysłać ten raport na adres ${item.emailAddress}?`,
            children: `System spróbuje wysłać wiadomość na podany adres. Jeśli nie uzupełniono pól temat i treść, program użyje wartości domyślnych. Czy na pewno chcesz kontynuować?`,
            labels: { confirm: `Tak, wyślij do ${item.emailAddress}`, cancel: 'Nie, nie wysyłaj' },
            confirmProps: {color: 'red'},
            centered: true,
            size: 'xl',
            onConfirm: () => doSendSingleItem(item)
        });
    }

    const deleteSingleItemSuccess = () => {
        showNotification({
            title: 'Usunięto prototyp!',
            message: 'Usunięto wybrany prototyp',
            color: 'green'
        });
    }

    const deleteSingleItemError = () => {
        showNotification({
            title: 'Nie udało się usunąć prototypu',
            message: 'Jeśli problem nie ustąpi proszę skontaktować się z administratorem',
            color: 'reload'
        });
    }

    const doDeleteSingleItem = (sendReport: SendReportItem) => {
        const act: Action = { action: 'delete', selectedElements: [sendReport.id!!] };

        startLoading();

        SendReportApi.performAction(act)
            .then(deleteSingleItemSuccess)
            .then(() => setSelectedRecords([...selectedRecords.filter(x => x !== sendReport)]))
            .then(reload)
            .catch(deleteSingleItemError)
            .finally(stopLoading);
    }

    const deleteSingleItem = (sendReport: SendReportItem) => {
        modals.openConfirmModal({
            title: `Czy na pewno chcesz usunąć ten prototyp?`,
            children: `Nie spowoduje usunięcia oryginalnego raportu ani plików`,
            labels: { confirm: `Tak, usuń`, cancel: 'Nie, nie usuwaj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: () => doDeleteSingleItem(sendReport)
        });
    }

    const reload = () => {
        startLoading();

        SendReportApi.search(searchQuery)
            .then(setPage)
            .catch(onReloadError)
            .finally(stopLoading);
    }

    const onSortStatusChange = (sortStatus: DataTableSortStatus<SendReportItem>) => {
        setSearchQuery({...searchQuery, sortDir: sortStatus.direction, sortBy: sortStatus.columnAccessor});
        setSortStatus(sortStatus);
    }

    useEffect(reload, [searchQuery]);

    return (
        <>
            <PageHeader
                title="Wysłane raporty"
                url="/send/report"
                action={<SendReportMenu doFilter={toggleFilters} sendSelected={sendSelected} deleteSelected={deleteSelected}/> }
            />

            <PageContent>
                <SendReportFilter
                    isOpen={isFiltersVisible}
                    justClose={() => setFiltersVisible(false)}
                    searchQuery={searchQuery}
                    doUpdate={setSearchQuery}
                />

                <DataTable
                    mt="xl"
                    striped
                    highlightOnHover
                    backgroundColor="transparent"
                    height="60vh"
                    withColumnBorders
                    styles={{ header: { background: 'white' } }}
                    loaderBackgroundBlur={1}
                    fetching={loading}
                    noRecordsText="Pusto..."
                    columns={effectiveColumns}
                    records={page?.content}
                    minHeight={150}
                    storeColumnsKey={key}
                    selectedRecords={selectedRecords}
                    onSelectedRecordsChange={setSelectedRecords}
                    style={{ marginBottom: '50px' }}
                    sortStatus={sortStatus}
                    onSortStatusChange={onSortStatusChange}
                    onRowContextMenu={(data) => {
                        showContextMenu([
                            {
                                key: 'edit-item',
                                title: 'Edytuj',
                                icon: <IconEdit size={16}/>,
                                onClick: () => editSendReport(data.record)
                            },

                            {
                                key: 'send-single-item',
                                title: 'Wyślij do klienta',
                                icon: <IconSend size={16} />,
                                onClick: () => sendSingleItem(data.record)
                            },

                            {
                                key: 'send-selected-menu-item',
                                title: 'Wyślij wszystkie',
                                icon: <IconSend size={16} />,
                                onClick: sendSelected
                            },

                            {
                                key: 'delete-single-item',
                                title: 'Usuń',
                                icon: <IconTrash size={16} />,
                                color: 'red',
                                onClick: () => deleteSingleItem(data.record)
                            },

                            {
                                key: 'delete-selected-items',
                                title: 'Usuń zaznaczone',
                                icon: <IconTrash size={16} />,
                                color: 'red',
                                onClick: deleteSelected
                            }
                        ])(data.event)
                    }}
                />

                <PaginationFooter
                    page={page}
                    setLimit={setLimit}
                    setPageNumber={setPageNumber}
                    pageNumber={searchQuery.page}
                    limit={searchQuery.limit}
                />
            </PageContent>

            <EditSendReportDialog
                isOpen={isEditDialogOpen}
                sendReport={editSendReportItem}
                justClose={editDialog.close}
                closeAndUpdate={() => { editDialog.close(); reload(); }}
            />
        </>
    );
}

export default SendReport;
