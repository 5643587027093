import {Button, Modal, NumberInput, Stack, Textarea, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {CreatePayload, DeviceApi} from "../../api/device/DeviceApi";
import {useState} from "react";
import {showNotification} from "@mantine/notifications";

export type CreateDeviceDialogAttrs = {
    isOpen: boolean;
    onClose: () => void;
    reload: () => void;
}

function CreateDeviceDialog(attrs: CreateDeviceDialogAttrs) {
    const [saving, setSaving] = useState(false);

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            unit: '',
            name: '',
            description: '',
            minAllowableTemp: 2.0,
            maxAllowableTemp: 8.0,
            serialNumber: '',
            clientVisibleName: '',
            clientEmailAddress: '',
            clientPhoneNumber: '',
        },

        validate: {
            unit: (value) => (value.length < 2 ? 'Przynajmniej dwa znaki.' : null),
            name: (v) => (v.length < 2 ? "Przynajmniej dwa znaki." : null)
        }
    });

    const onCreated = () => {
        attrs.onClose();
    };

    const onCreateError = () => {
        showNotification(
            {
                title: 'Nie udało się zapisać urządzenia',
                message: 'Proszę upewnić się, że jednostka nie jest zajęta.',
                color: 'red'
            }
        );

        form.setFieldError('unit', 'Proszę upewnić się, że jednostka nie jest zajęta.');
    }

    const onSuccess = () => {
        showNotification(
            {
                title: 'Nowe urządzenie!',
                message: 'Utworzyłeś nowe urządzenie!',
                color: 'green'
            }
        );
    }

    const create = (values: any) => {
        setSaving(true);

        const p: CreatePayload = {
            ...values,
            clientVisibleName: values.clientVisibleName === '' ? undefined : values.clientVisibleName,
            clientEmailAddress: values.clientEmailAddress === '' ? undefined : values.clientEmailAddress,
            clientPhoneNumber: values.clientPhoneNumber === '' ? undefined : values.clientPhoneNumber,
        };

        DeviceApi.create(p)
            .then(onCreated)
            .then(attrs.reload)
            .then(onSuccess)
            .catch(onCreateError)
            .finally(() => setSaving(false));
    };

    return (
        <Modal
            title="Utwórz urządzenie"
            opened={attrs.isOpen}
            onClose={attrs.onClose}
        >

            <form onSubmit={form.onSubmit((values) => create(values))}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Jednostka"
                        placeholder="1234"
                        variant="filled"
                        key={form.key("unit")}
                        {...form.getInputProps("unit")}
                    />

                    <TextInput
                        withAsterisk
                        label="Nazwa urządzenia"
                        placeholder="Urządzenie pomiarowe"
                        variant="filled"
                        key={form.key("name")}
                        {...form.getInputProps("name")}
                    />

                    <Textarea
                        label="Opis"
                        placeholder="Urządzenie pomiarowe"
                        resize={'vertical'}
                        minRows={3}
                        variant="filled"
                        key={form.key("description")}
                        {...form.getInputProps("description")}
                    />

                    <NumberInput
                        label="Min. dopuszczalna temperatura"
                        step={.1}
                        key={form.key("minAllowableTemp")}
                        {...form.getInputProps("minAllowableTemp")}
                    />

                    <NumberInput
                        label="Maks. dopuszczalna temperatura"
                        step={.1}
                        key={form.key('maxAllowableTemp')}
                        {...form.getInputProps("maxAllowableTemp")}
                    />

                    <TextInput
                        label="Nazwa klienta"
                        key={form.key("clientVisibleName")}
                        {...form.getInputProps("clientVisibleName")}
                    />

                    <TextInput
                        label="Adres E-mail"
                        key={form.key("clientEmailAddress")}
                        {...form.getInputProps("clientEmailAddress")}
                    />

                    <TextInput
                        label="Numer telefonu"
                        key={form.key("clientPhoneNumber")}
                        {...form.getInputProps("clientPhoneNumber")}
                    />

                    <TextInput
                        withAsterisk
                        label="Numer seryjny"
                        placeholder="Numer seryjny"
                        variant="filled"
                        key={form.key("serialNumber")}
                        {...form.getInputProps("serialNumber")}
                    />

                    <Button
                        loading={saving}
                        loaderProps={{type: 'dots'}}
                        type="submit"
                    >
                        Utwórz
                    </Button>
                </Stack>
            </form>

        </Modal>
    );
}

export default CreateDeviceDialog;
