// noinspection JSUnusedGlobalSymbols

import {Client} from "../client/ClientApi";
import {PageRequest} from "../../models/api/PageRequest";
import {Api} from "../Api";
import {HttpClient} from "../_http/HttpClient";
import Page from "../../models/api/Page";
import {SortedRequest} from "../../models/api/SortedRequest";

export type Device = {
    id: string;
    unit: string;
    name?: string;
    description?: string;
    location?: string;
    client?: Client;
    minAllowableTemp?: number;
    maxAllowableTemp?: number;
    generator?: string;
    thermostat?: string;
    points?: string;
    ftpDirectory?: string;
    doesOverrideFooterRight: boolean;
    clientVisibleName?: string;
    clientEmailAddress?: string;
    clientPhoneNumber?: string;
}

export type CreatePayload = {
    unit: string;
    name?: string;
    description?: string;
    location?: string;
    clientId?: string;
    minAllowableTemp?: number;
    maxAllowableTemp?: number;
    generator?: string;
    thermostat?: string;
    points?: string;
    ftpDirectory?: string;
    clientVisibleName?: string;
    clientEmailAddress?: string;
    clientPhoneNumber?: string;
};

export type SearchQuery = { q?: string; clientId?: string; } & PageRequest & SortedRequest;

export type CreateDeviceResult = { device?: Device };

export type Action = { action: 'delete', selectedElements: string[] };

export type ImportDeviceList = {
    devices: Device[];
    errors: { rowIndex: number }[]
}

export class DeviceApi {
    static search(query: SearchQuery): Promise<Page<Device>> {
        const url = Api.getUrl("/device");
        return HttpClient.get<Page<Device>>(url, { ...query });
    }

    static create(p: CreatePayload) {
        const url = Api.getUrl("/device/create");
        return HttpClient.post(url, {}, { ...p });
    }

    static edit(p: CreatePayload) {
        const url = Api.getUrl("/device/edit");
        return HttpClient.post(url, {}, {...p});
    }

    static performAction(act: Action) {
        const url = Api.getUrl("/device/action");
        return HttpClient.postAndExpectNoContent(url, {}, { ...act });
    }

    static getById(id: string) {
        const url = Api.getUrl('/device/get-by-id');
        return HttpClient.get<Device>(url, { id });
    }

    static uploadFooterRight(deviceId: string, file: File | null) {
        const url = Api.getUrl('/device/handle-footer-upload');
        const formData = new FormData();
        formData.append('file', file ?? new File([], 'null.jpeg'));
        return HttpClient.postFormData(url, { id: deviceId }, formData);
    }

    static getFooterRightUrl(deviceId: string) {
        const baseUrl = Api.getUrl('/device/get-footer-right');
        return HttpClient.getUrl(baseUrl, { id: deviceId });
    }

    static resetFooterRight(deviceId: string) {
        const url = Api.getUrl('/device/reset-footer-right');
        return HttpClient.postAndExpectNoContent(url, {id: deviceId}, {});
    }

    static importDevices(file: File) {
        const url = Api.getUrl('/device/import_device_list');
        const form = new FormData();
        form.append('file', file);
        return HttpClient.postFormDataAndGetJson<ImportDeviceList>(url, {}, form);
    }
}
