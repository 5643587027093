import {PageRequest} from "../../../models/api/PageRequest";
import {SortedRequest} from "../../../models/api/SortedRequest";
import {HttpClient} from "../../_http/HttpClient";
import Page from "../../../models/api/Page";
import {Api} from "../../Api";
import {Device} from "../../device/DeviceApi";

export type CalendarEvent = {
    id: string;
    unit: string;
    comment: string;
    date: [number, number, number];
    endDate: [number, number, number] | null;
    devices: Device[];
}

export type SearchQueryBase = {
    q?: string;
    unit?: string;

    /**
     * yyyy-MM-dd
     */
    fromDate?: string;

    /**
     * yyyy-MM-dd
     */
    toDate?: string;
}

export type SearchQuery = SearchQueryBase & PageRequest & SortedRequest;

export type Action = {
    selectedElements: string[];
    action: 'delete';
}

export type CreatePayload = {
    unit: string;
    date: string;
    comment: string;
    endDate?: string | null;
    addPrefixAutomatically?: boolean | undefined | null;
}

class CalendarEventApi {
    static search(query: SearchQuery) {
        const url = Api.getUrl('/calendar/event');
        return HttpClient.get<Page<CalendarEvent>>(url, { ...query });
    }

    static performAction(action: Action) {
        const url = Api.getUrl('/calendar/event/action');
        return HttpClient.postAndExpectNoContent(url, {}, { ...action});
    }

    static create(payload: CreatePayload) {
        const url = Api.getUrl('/calendar/event/create');
        return HttpClient.post<CalendarEvent>(url, {}, { ...payload });
    }
}

export default CalendarEventApi;